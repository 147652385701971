import {handleResponse} from ".";

const API = process.env.REACT_APP_API_URL;

export const getAuthUser = () => {
    return fetch(`${API}/auth/getAuth-user`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${isAuthenticated()}`,
        },
    }).then(handleResponse).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
};

export const getTokenApi = (body) => {

    console.log("API URL: " + `${API}/sms/initiate-verification`);
    console.log("getTokenApi body: " + JSON.stringify(body));

    return fetch(`${API}/sms/initiate-verification`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': 'https://techpay.ebs-rcm.com',
            // 'Access-Control-Allow-Methods': 'POST, GET, PUT',
            // 'Access-Control-Allow-Headers': 'Content-Type'

        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}

export const resetPasswordApi = (body) => {
    return fetch(`${API}/sms/initiate-password-reset`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}

export const registerUser = (body) => {
    return fetch(`${API}/sms/check-verification-code-web`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}

export const updateUser = (body) => {
    return fetch(`${API}/user/update_web`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${isAuthenticated()}`,
        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}

export const loginUser = (body) => {
    return fetch(`${API}/auth/user-login`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}

export const authenticateUser = (token) => {
    if (typeof window != 'undefined') {
        localStorage.setItem('techpay_token', token);
    }
};

export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    if (localStorage.getItem('techpay_token')) {
        return localStorage.getItem('techpay_token');
    } else {
        return false;
    }
};

export const logout = () => {
    if (typeof window != 'undefined') {
        localStorage.removeItem('techpay_token')
        window.location.replace("/")
    }
}

export const resetPassowrd = (body) => {
    return fetch(`${API}/sms/check-password-reset-verification-code`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then(response => {
        return response.json();
    }).catch(err => {
        console.log(err);
    });
}